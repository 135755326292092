@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* GameNights.css */
.game-nights {
  display: flex;
  align-items: flex-start;
  background-color: #111; /* Dark background color for contrast */
  color: white;
  padding: 50px 5%; /* Responsive padding */
  box-sizing: border-box;
  width: 100%;
  height: 625px;
  position: relative;
}

.game-nights-image-content {
  position: relative;
  width: calc(35% - 5%);
  height: 400px; /* Adjust this height if needed */
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.game-nights-image-content img.first-image {
  position: absolute;
  width: 295px;
  height: 368px;
  transform: rotate(-5.305deg);
  z-index: 2; /* Ensure this image is on top */
  border: 2px solid white;
}

.game-nights-image-content img.second-image {
  position: absolute;
  width: 295px;
  height: 368px;
  transform: rotate(-14.019deg);
  z-index: 1; /* Ensure this image is below the first image */
  border: 2px solid white;
}

.game-nights-image-content img {
  width: 100%; /* Responsive image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Rounded corners for the image */
}

.game-nights-text-content {
  flex: 1; /* Take the remaining space */
  padding-left: 5%;
}

.game-nights-text-content h2 {
  font-size: 48px; /* Large font size for the title */
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 20px;
  align-self: flex-start;
}

.game-nights-text-content p {
  font-size: 18px;
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
}

.game-nights-blockquote {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 275;
  line-height: 31px; /* 193.75% */
  letter-spacing: 0.48px;
  margin-top: 10px; /* Adjust this value to position the blockquote under the images */
  position: absolute;
  bottom: -60px; /* Position it at the bottom of the `game-nights-image-content` container */
  left: 5%;
  transform: translateX(-50%);
  transform: rotate(-12.54deg);
  z-index: 3;
  width: calc(100% - 10px);
  text-align: center; /* Center the text */
}

.game-nights-get-access-button {
  display: block;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 0;
  align-items: center;
  background-color: #f38806; /* Discord brand color */
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  font-style: italic;
  font-family: "Nunito", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid white; /* White border around the button */
}

.game-nights-get-access-button img {
  width: 24px; /* Size of the icon */
  margin-right: 8px; /* Space between the icon and text */
}

/* Reset margins and paddings */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Responsive adjustments for GameNights */
@media (max-width: 768px) {
  .game-nights {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center the content */
    padding: 20px; /* Adjust padding for smaller screens */
    height: auto; /* Let the height be determined by content */
    text-align: center;
  }

  .game-nights-image-content {
    width: 80%; /* Adjust width to control the size of images */
    margin-top: 50px; /* Push content down to make space for the 'Join Discord' button */
    position: relative;
    display: block; /* Change to block to stack the images and blockquote */
    text-align: center;
  }

  .game-nights-image-content img.first-image,
  .game-nights-image-content img.second-image {
    width: 60%; /* Make images smaller */
    position: absolute; /* Change position to relative */
    left: 50%; /* Center the image */
    transform: translateX(
      -50%
    ); /* Adjust translate to ensure image is centered */
    /* margin-bottom: -50px; Overlap images */
  }

  .game-nights-image-content img.first-image {
    left: 50%; /* Start at 50% from the left */
    transform: translateX(-50%) rotate(-5.305deg); /* Center then rotate */
    /* Make sure the margin-bottom is negative half the image height to overlap */
    margin-bottom: -184px;
  }
  .game-nights-image-content img.second-image {
    z-index: 1; /* Ensure this image is below the first image */
    transform: translateX(-50%) rotate(-14.019deg);
  }

  .game-nights-blockquote {
    position: relative; /* Adjust to normal flow */
    transform: rotate(-14.019deg);
    margin-top: 20px; /* Add margin to push it below the image */
    bottom: -95%; /* Remove bottom positioning */
    left: 10%; /* Center the blockquote */
    z-index: 3; /* Keep z-index or adjust as necessary */
    width: 90%; /* Adjust width */
    top: 90%;
    text-align: center; /* Center the text */
  }

  .game-nights-text-content {
    order: -1; /* Move text above the images */
    padding: 0 15px; /* Adjust padding for text content */
    margin-bottom: 20px; /* Space between text and images */
  }

  .game-nights-get-access-button {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Space above the button */
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the button */
  }
}
