@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* AccessNBAStreams.css */
.access-nba-streams {
  display: flex;
  align-items: flex-start;
  background-color: #111; /* Dark background color for contrast */
  color: white;
  padding: 50px 5%; /* Responsive padding */
  box-sizing: border-box;
  width: 100%;
  height: 625px;
}

.access-nba-streams-image-content {
  width: calc(35% - 5%);
  margin-right: 5%; /* Space between the image and the text */
  margin-top: 0;
}

.access-nba-streams-image-content img {
  width: 100%; /* Responsive image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Rounded corners for the image */
  border: 2px solid white;
}

.access-nba-streams-text-content {
  flex: 1; /* Take the remaining space */
  padding-left: 5%;
}

.access-nba-streams-text-content h2 {
  font-size: 48px; /* Large font size for the title */
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 20px;
}

.access-nba-streams-text-content p {
  font-size: 18px;
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
}

.access-nba-streams-blockquote {
  font-family: "Poppins", sans-serif;
  font-style: italic; /* Italicize the quote */
  margin-top: 10px; /* Space above the quote */
}

.access-nba-streams-get-access-button {
  display: block;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 0;
  align-items: center;
  background-color: #f38806; /* Discord brand color */
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  font-style: italic;
  font-family: "Nunito", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid white; /* White border around the button */
}

.access-nba-streams-get-access-button img {
  width: 24px; /* Size of the icon */
  margin-right: 8px; /* Space between the icon and text */
}

/* Reset margins and paddings */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Responsive adjustments for AccessNBAStreams */
@media (max-width: 768px) {
  .access-nba-streams {
    flex-direction: column; /* Stack elements vertically */
    height: auto; /* Adjust height to fit content */
    padding: 25px 5%; /* Reduce padding */
  }

  .access-nba-streams-text-content {
    order: 1; /* Text comes first */
    padding-left: 0; /* Reset padding */
    text-align: center; /* Center text for mobile */
  }

  .access-nba-streams-text-content h2 {
    font-size: 36px; /* Reduce font size for the title */
    margin-bottom: 15px; /* Adjust margin */
  }

  .access-nba-streams-text-content p {
    font-size: 16px; /* Reduce paragraph font size */
  }

  .access-nba-streams-image-content {
    order: 2; /* Image comes after text */
    width: 100%; /* Full width images */
    margin-right: 0; /* Reset margin */
    margin-top: 20px; /* Space between text and image */
  }

  .access-nba-streams-image-content img {
    margin-bottom: 10px; /* Space between image and blockquote */
  }

  .access-nba-streams-blockquote {
    /* Adjust blockquote styling if needed */
  }

  .access-nba-streams-get-access-button {
    width: auto; /* Full width button */
    margin: 20px auto; /* Center button with margin */
    padding: 10px 30px; /* Adjust padding */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .access-nba-streams-text-content h2 {
    font-size: 30px; /* Even smaller font size for the title */
  }

  .access-nba-streams-text-content p {
    font-size: 14px; /* Smaller paragraph font size */
  }

  .access-nba-streams-get-access-button {
    padding: 8px 25px; /* Smaller padding */
  }
}
