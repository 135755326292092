/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* ActiveCard.css */
.active-card {
  display: flex;
  align-items: flex-start;
  background-color: #111; /* Dark background color for contrast */
  color: white;
  padding: 50px 5%; /* Responsive padding */
  box-sizing: border-box;
  width: 100%;
  min-height: 625px;
}

.active-card-text-content {
  max-width: 60%; /* Adjust text content max width */
  margin-right: 10%; /* Space between text and image */
  margin-top: 0;
}

.active-card-text-content h2 {
  font-size: 61px; /* Large font size for the member count */
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 20px; /* Space below the heading */
  margin-top: 0;
}

.active-card-text-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px; /* Paragraph font size */
  line-height: 1.6; /* Line height for better readability */
  margin-top: 0;
}

.active-card-join-discord-button {
  display: inline-flex; /* Use flexbox for aligning image and text */
  align-items: center; /* Center items vertically */
  background-color: #f38806; /* Discord's brand color */
  color: black;
  text-decoration: none;
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  border: 2px solid white; /* White border around the button */
  font-family: "Nunito", sans-serif;
  font-style: italic;
}

.active-card-discord-logo {
  width: 20px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Space between the logo and text */
}

.active-card-image-content {
  display: flex; /* Make it a flex container */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children on the cross axis */
  justify-content: center;
  max-width: 35%; /* Adjust image content max width */
  transform: rotate(3deg);
  overflow: hidden; /* Ensure the overflow from rotation is hidden */
}

.active-card-image-content img {
  max-width: 100%; /* Responsive image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Rounded corners for the image */
  height: 230px;
  width: 415px;
  display: block;
  border: 2px solid white;
}

.active-card-image-content blockquote {
  font-family: "Poppins", sans-serif;
  font-style: italic; /* Italicize the quote */
  margin-top: 10px; /* Space above the quote */
}

/* Add global styles to remove default margins */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal scroll */
}

@media (max-width: 768px) {
  .active-card {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center the content */
    padding: 30px 5%; /* Adjust padding for smaller screens */
    min-height: auto; /* Let the height be determined by content */
  }

  .active-card-text-content {
    max-width: 100%; /* Allow text content to fill the container */
    margin-right: 0; /* Reset margin */
    margin-top: 20px; /* Provide space between image and text */
    text-align: center; /* Center text content */
  }

  .active-card-text-content h2,
  .active-card-text-content p {
    margin-top: 10px; /* Adjust spacing */
  }

  .active-card-text-content h2 {
    font-size: 36px; /* Reduce font size for headers */
  }

  .active-card-text-content p {
    font-size: 16px; /* Adjust paragraph font size for readability */
  }

  .active-card-join-discord-button {
    margin: 20px auto 0; /* Center the button and adjust spacing */
    padding: 10px 20px; /* Adjust button padding */
    margin-bottom: 20px;
  }

  .active-card-image-content {
    /* No need to rotate images on mobile for simplicity */
    transform: none;
    margin-bottom: 20px; /* Space between the images and text content */
    width: 80%; /* Adjust width to make the image smaller */
  }

  .active-card-image-content img {
    /* Adjust the size of the images to fit the mobile layout */
    max-width: 100%;
    height: auto;
    border: 1px solid white; /* Adjust border size */
  }

  .active-card-image-content blockquote {
    /* Ensure the blockquote is properly aligned and styled */
    margin-top: 15px;
    font-size: 14px; /* Adjust font size */
  }
}

/* Additional media query for even smaller screens, if necessary */
@media (max-width: 480px) {
  .active-card-text-content h2 {
    font-size: 28px; /* Further reduce title size for smaller screens */
  }
  .active-card-image-content {
    width: 90%; /* Allow image to take more width if needed */
  }
}
