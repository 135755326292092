/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* FavoritePlayer.css */
.favorite-player {
  display: flex;
  align-items: flex-start;
  background-color: #111; /* Dark background color for contrast */
  color: white;
  padding: 50px 5%; /* Responsive padding */
  box-sizing: border-box;
  width: 100%;
  min-height: 625px;
}

.favorite-player-text-content {
  max-width: 60%; /* Adjust text content max width */
  margin-right: 10%; /* Space between text and image */
  margin-top: 0;
}

.favorite-player-text-content h2 {
  font-size: 61px; /* Large font size for the member count */
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 20px; /* Space below the heading */
  margin-top: 0;
}

.favorite-player-text-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px; /* Paragraph font size */
  line-height: 1.6; /* Line height for better readability */
  margin-top: 0;
}

.favorite-player-join-discord-button {
  display: inline-flex; /* Use flexbox for aligning image and text */
  align-items: center; /* Center items vertically */
  background-color: #f38806; /* Discord's brand color */
  color: black;
  text-decoration: none;
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  border: 2px solid white; /* White border around the button */
  font-family: "Nunito", sans-serif;
  font-style: italic;
}

.favorite-player-discord-logo {
  width: 20px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Space between the logo and text */
}

.favorite-player-image-content {
  display: flex; /* Make it a flex container */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children on the cross axis */
  justify-content: center;
  max-width: 35%; /* Adjust image content max width */
  transform: rotate(3deg);
  overflow: hidden; /* Ensure the overflow from rotation is hidden */
}

.favorite-player-image-content img {
  max-width: 100%; /* Responsive image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Rounded corners for the image */
  height: 230px;
  width: 415px;
  display: block;
  border: 3px solid white;
}

.favorite-player-image-content blockquote {
  font-family: "Poppins", sans-serif;
  font-style: italic; /* Italicize the quote */
  margin-top: 10px; /* Space above the quote */
}

/* Add global styles to remove default margins */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal scroll */
}

/* MOBILE ACCESSIBLE */
@media (max-width: 768px) {
  .favorite-player {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    min-height: auto;
  }

  .favorite-player-text-content,
  .favorite-player-join-discord-button,
  .favorite-player-image-content,
  .favorite-player-image-content img,
  .favorite-player-image-content blockquote {
    max-width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }

  .favorite-player-text-content h2 {
    font-size: 36px; /* Adjust the font size for smaller screens */
    margin-bottom: 10px;
  }

  .favorite-player-text-content p {
    font-size: 16px; /* Adjust the font size for smaller screens */
  }

  .favorite-player-join-discord-button {
    justify-content: center;
    margin-top: 20px;
    padding: 10px 20px;
  }

  .favorite-player-discord-logo {
    /* Adjust discord logo size if needed */
  }

  .favorite-player-image-content {
    transform: none;
    order: 3; /* Image comes after the discord button */
  }

  .favorite-player-image-content img {
    /* Adjust the size of the image as needed */
    height: auto;
    width: 80%; /* Make the image a bit smaller */
    margin: 0 auto; /* Center the image */
  }

  .favorite-player-image-content blockquote {
    order: 4; /* Blockquote comes after the image */
    margin-top: 10px;
    padding: 0 10px; /* Add some padding */
  }
}
