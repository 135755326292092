/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* ServerDescription.css */
.server-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111; /* Dark background color */
  color: white;
  padding: 50px 5%; /* Adjust padding as needed */
  box-sizing: border-box; /* Include padding in the width */
  width: 100%; /* Full width */
  /* margin-top: -50px; Adjust if needed to overlay on the header */
  height: 625px;
}

.server-description-text-content {
  max-width: 60%; /* Adjust text content max width */
  margin-right: 2%; /* Space between text and image */
}

.server-description-text-content h2 {
  font-size: 61px; /* Large font size for the member count */
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 20px; /* Space below the heading */
}

.server-description-text-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px; /* Paragraph font size */
  line-height: 1.6; /* Line height for better readability */
}

.server-description-join-discord-button {
  display: inline-flex; /* Use flexbox for aligning image and text */
  align-items: center; /* Center items vertically */
  background-color: #f38806; /* Discord's brand color */
  color: black;
  text-decoration: none;
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  border: 2px solid white; /* White border around the button */
  font-family: "Nunito", sans-serif;
  font-style: italic;
}

.server-description-discord-logo {
  width: 20px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 8px; /* Space between the logo and text */
}

.server-description-image-content {
  max-width: 35%; /* Adjust image content max width */
  transform: rotate(3deg);
  overflow: hidden; /* Ensure the overflow from rotation is hidden */
}

.server-description-image-content img {
  max-width: 100%; /* Responsive image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Rounded corners for the image */
  height: 230px;
  width: 415px;
  border: 2px solid white;
}

.server-description-image-content blockquote {
  font-family: "Poppins", sans-serif;
  font-style: italic; /* Italicize the quote */
  margin-top: 10px; /* Space above the quote */
}

/* Add global styles to remove default margins */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal scroll */
}

/* Responsive adjustments for server description */
@media (max-width: 768px) {
  .server-description {
    flex-direction: column;
    height: auto;
    padding: 30px 5%;
  }

  .server-description-text-content,
  .server-description-image-content,
  .server-description-join-discord-button {
    max-width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 15px; /* Ensure spacing between elements */
  }

  .server-description-text-content h2 {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .server-description-text-content p {
    font-size: 16px;
  }

  .server-description-image-content {
    display: flex;
    flex-direction: column; /* Stack image and blockquote vertically */
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    transform: none; /* Remove any rotations */
    padding: 0; /* Reset any padding if necessary */
  }

  .server-description-image-content img {
    width: 100%; /* Adjust the width of the image */
    margin-bottom: 10px; /* Space between the image and the blockquote */
  }

  .server-description-image-content blockquote {
    width: 90%; /* Adjust the width of the blockquote */
    margin: 0; /* Reset margins */
    padding: 0 10px; /* Add padding for better text alignment */
    text-align: center; /* Ensure text is centered */
  }
}

/* Additional media query for even smaller screens if necessary */
@media (max-width: 480px) {
  .server-description-text-content h2 {
    font-size: 28px; /* Further reduce title size for small screens */
  }

  .server-description-image-content img {
    max-width: 90%; /* Allow image to take more width if needed */
  }
}
