@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* Header.css */
.header {
  position: relative;
  display: flex;
  align-items: center;
  background: url("basketball-legends.png") no-repeat center center;
  background-size: cover;
  padding: 20px 40px;
  min-height: 625px; /* Adjust the height as needed */
}

.logo-container {
  align-self: start;
  margin-left: 20px; /* Space from the left edge */
  border: 3px solid white;
  padding-left: 3px;
  padding-top: 3px;
  padding-right: 3px;
  background: white;
  border-radius: 8px; /* Slightly rounded corners */
  overflow: hidden; /* Ensures the image does not break out of border-radius */
}

.logo {
  width: 78px;
  height: 78px;
}

.navigation {
  display: flex;
  gap: 20px;
  position: absolute;

  right: 250px; /* Adjust this value to move closer to the 'JOIN DISCORD' button */
  top: 32px; /* Position from the top */
}

.nav-button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  font-weight: 800; /* Extra bold weight */
  font-size: 19px; /* Size of the font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.join-discord {
  display: flex;
  align-items: center;
  background: #2c2f33; /* Discord bluish color */
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  position: absolute;
  border: 2px solid white; /* White border around the button */
  right: 40px; /* Space from the right edge */
  top: 32px; /* Position from the top */
}

.join-discord img {
  width: 24px; /* Size of the Discord logo */
}

.join-discord span {
  color: white;
  font-weight: bold;
  margin-left: 8px; /* Space between the icon and text */
}

.header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 192px; /* Large font size for the title */
  font-weight: 800; /* Extra bold weight - assuming you have this weight downloaded */
  pointer-events: none; /* Allows clicks to pass through */
  font-family: "Poppins", sans-serif; /* The font-family you want to use */
  color: transparent; /* Makes the inside of the letters transparent */
  -webkit-text-stroke: 1px #ffffff; /* Stroke effect for webkit browsers */
  white-space: nowrap; /* Prevents the text from wrapping */
  max-width: 100%; /* Ensures it doesn't exceed the width of the parent container */
  overflow: hidden; /* Prevents text from overflowing */
}

/* MOBILE DEVICE */
@media (max-width: 768px) {
  .header {
    padding: 10px 20px; /* Reduced padding */
    min-height: 300px; /* Adjusted minimum height */
    flex-direction: column; /* Stack items vertically */
  }

  .logo-container {
    margin-left: 0; /* Align logo container in the center */
    margin-bottom: 20px; /* Space between logo and navigation */
  }

  .navigation {
    position: static; /* Remove absolute positioning */
    flex-direction: column; /* Stack navigation items vertically */
    gap: 10px; /* Reduce gap size */
    align-items: center; /* Align navigation items in the center */
  }

  .nav-button {
    font-size: 16px; /* Reduce font size */
  }

  .join-discord {
    position: static; /* Remove absolute positioning */
    margin-top: 20px; /* Add margin at the top */
    width: 100%; /* Full width button */
    justify-content: center; /* Center icon and text inside the button */
  }

  .join-discord img {
    width: 20px; /* Adjust logo size */
  }

  .header-title {
    font-size: 96px; /* Adjust title size for mobile */
    top: 30%; /* Adjust top position to prevent overlap */
    -webkit-text-stroke: 0.5px #ffffff; /* Adjust stroke thickness */
    transform: translate(
      -50%,
      -30%
    ); /* Adjust translation to match new top value */
  }
}

/* Additional media query for even smaller screens if necessary */
@media (max-width: 480px) {
  .header-title {
    font-size: 72px; /* Further reduce title size for small screens */
  }
}
