.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url("basketball-wooden-surface.jpg") no-repeat center center;
  background-size: cover;
  padding: 20px;
  position: relative;
  min-height: 135px;
  color: white;
  margin-top: 10px;
}

.footer::before {
  content: "";
  display: block;
  height: 10px; /* Same as your margin-top value */
  background-color: #2c2f33; /* The color you want for the space */
  position: absolute;
  top: -10px; /* Position it above the footer */
  left: 0;
  right: 0;
}

.footer .logo {
  width: 78px;
  height: 78px;
  /* Other styles for logo container as needed */
}

.footer .menu {
  display: flex;
  justify-content: center; /* Center menu items horizontally */
  position: absolute;
  width: 100%; /* Use the full width of the footer */
  top: 50%; /* Position at the half of the footer height */
  transform: translateY(-50%); /* Align vertically center */
  gap: 20px; /* Space between menu items */
}

.footer .menu a {
  /* Styles for your menu items similar to the .nav-button */
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.footer .menu a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.footer .footer-legal {
  position: absolute;
  bottom: 20px; /* Position the legal text at the bottom of the footer */
  width: 100%; /* Span the full width */
  text-align: center; /* Center the text */
  font-size: 14px; /* Adjust the font size as needed */
  color: white; /* Set the text color */
}

.footer .join-discord {
  display: flex;
  align-items: center;
  background: #2c2f33; /* Discord bluish color */
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  position: absolute;
  border: 2px solid white; /* White border around the button */
  right: 40px; /* Space from the right edge */
  top: 32px; /* Position from the top */
  margin-right: 40px;
}

/* MOBILE RESPONSIVE */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: auto; /* Adjust the height based on the content */
  }

  .footer .menu {
    flex-direction: column; /* Stack menu items vertically */
    position: static; /* Remove absolute positioning */
    transform: none; /* Remove vertical centering transform */
    gap: 10px; /* Reduce space between menu items */
  }

  .footer .menu a {
    font-size: 16px; /* Adjust font size for mobile */
  }

  .footer .footer-legal {
    position: static; /* Remove absolute positioning */
    bottom: auto; /* Remove bottom positioning */
    margin-top: 20px; /* Add space above the legal text */
  }

  .footer .join-discord {
    position: static; /* Remove absolute positioning */
    right: auto; /* Remove right positioning */
    top: auto; /* Remove top positioning */
    margin: 20px auto; /* Add margin for vertical and horizontal centering */
    width: 80%; /* Full width button */
  }
}
