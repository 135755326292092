@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");

/* ChooseTeam.css */
.choose-team {
  display: flex;
  align-items: flex-start;
  background-color: #111; /* Dark background color for contrast */
  color: white;
  padding: 50px 5%; /* Responsive padding */
  box-sizing: border-box;
  width: 100%;
  height: 625px;
}

.choose-team-image-content {
  width: calc(35% - 5%);
  margin-right: 5%; /* Space between the image and the text */
  margin-top: 0;
  position: relative;
  margin-right: 5%;
}

.choose-team-image-content img {
  width: 100%; /* Responsive image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px; /* Rounded corners for the image */
  border: 2px solid white;
  transform: rotate(-10deg);
}

.choose-team-text-content {
  flex: 1; /* Take the remaining space */
  padding-left: 5%;
}

.choose-team-text-content h2 {
  font-size: 48px; /* Large font size for the title */
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 20px;
  align-self: flex-start;
}

.choose-team-text-content p {
  font-size: 18px;
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
}

.choose-team-blockquote {
  position: absolute;
  bottom: -20%;
  transform: translateX(-50%) rotate(-10deg);
  z-index: 2;
  width: calc(100% - 10px); /* Adjust width as needed */
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: italic; /* Italicize the quote */
  margin-top: 10px; /* Space above the quote */
  transform: rotate(-10deg);
}

.choose-team-get-access-button {
  display: block;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 0;
  align-items: center;
  background-color: #f38806; /* Discord brand color */
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  font-style: italic;
  font-family: "Nunito", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid white; /* White border around the button */
}

.choose-team-get-access-button img {
  width: 24px; /* Size of the icon */
  margin-right: 8px; /* Space between the icon and text */
}

/* Reset margins and paddings */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* MOBILE ACCESSIBLE */
@media (max-width: 768px) {
  .choose-team {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: auto; /* Adjust the height based on the content */
  }

  .choose-team-text-content,
  .choose-team-image-content,
  .choose-team-blockquote {
    max-width: 100%; /* Allow content to fill the width */
    margin: 0; /* Reset margins */
  }

  .choose-team-text-content h2,
  .choose-team-text-content p {
    margin-bottom: 10px; /* Space below the text */
  }

  .choose-team-get-access-button {
    order: 2; /* Position the button after the text */
    width: 90%; /* Full width for button */
    margin-top: 10px; /* Space above the button */
    margin-bottom: 10px; /* Space below the button */
  }

  .choose-team-image-content {
    order: 3; /* Position the image after the button */
    margin-top: 10px; /* Space above the image */
    width: 100%; /* Full width for image */
  }

  .choose-team-image-content img {
    transform: none; /* Remove rotation for mobile */
    width: 80%; /* Full width for image */
    margin-bottom: 10px; /* Space below the image */
  }

  .choose-team-blockquote {
    order: 4; /* Position the blockquote after the image */
    transform: none; /* Remove rotation */
    width: 90%; /* Adjust blockquote width */
    margin-top: 0; /* Reset margin */
    text-align: center;
    position: relative;
    bottom: auto; /* Reset bottom position */
  }
}
