/* SidebarCard.css */
.sidebar-card {
  background-color: #2c2f33; /* Dark background for card */
  padding: 30px;
  margin-bottom: 15px; /* Spacing between cards */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  color: white; /* Text color */
  position: absolute; /* Position the sidebar card absolutely within its parent */
  top: 450px; /* Adjust this value to move the sidebar card up by 50px; change as needed */
  right: 40px; /* Position from the right; adjust as needed */
  z-index: 10; /* Ensure the sidebar card is above the header */
  border: 2px solid white;
}

/* SidebarCard.css */
.sidebar-card .social-links a {
  margin-right: 40px; /* Spacing between icons */
}

.sidebar-card .social-links img {
  width: 24px; /* Adjust size as needed */
  height: 24px;
}

.sidebar-card h4 {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #ffffff;
}

/* Responsive adjustments for sidebar card */
@media (max-width: 768px) {
  .sidebar-card {
    position: static; /* Let the card flow within the document */
    /* margin-bottom: 30px; Add some margin to the bottom */
    box-shadow: none; /* Optionally remove the shadow for a flatter design on smaller screens */
    border: none; /* Optionally remove border */
    padding: 15px; /* Adjust padding for smaller screens */
    border-radius: 0; /* Optionally remove border radius for a full-width card */
    text-align: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }

  .sidebar-card .social-links a {
    margin-right: 15px; /* Adjust spacing between icons */
  }

  .sidebar-card .social-links img {
    width: 20px; /* Adjust icon size for smaller screens */
    height: 20px;
  }

  .sidebar-card h4 {
    font-size: 16px; /* Adjust font size for headings */
  }
}

/* Additional media query for very small screens, if necessary */
@media (max-width: 480px) {
  .sidebar-card {
    padding: 10px; /* Further reduce padding */
  }

  .sidebar-card h4 {
    font-size: 14px; /* Further reduce heading font size */
  }

  .sidebar-card .social-links a {
    margin-right: 10px; /* Reduce spacing between icons even more */
  }
}
